<template>

  <div class="auth-wrapper auth-v2">

    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <tally-sale-logo />

        <h2 class="brand-text text-primary ml-1">
          Tally Aid
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Activate Tally Sale Account"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- OTP -->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-overlay
          :show="isLoading"
          spinner-variant="primary"
          spinner-type="grow"
          rounded="sm"
          style="width: 100%; padding: 10px;"
        >
          <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
          >
            <b-card-title
              title-tag="h2"
              class="font-weight-bold mb-1 text-center"
            >
              OTP Verification 🔑
            </b-card-title>
            <b-card-text class="mb-2 text-center">
              Your Account is created. Please enter the 6-digit OTP sent to your email to activate.
            </b-card-text>

            <!-- form -->
            <form
              class="otp-form text-center"
              @submit.prevent="submitOTP"
            >
              <div
                v-for="(input, index) in otpInputs"
                :key="index"
                class="otp-input"
              >
                <input
                  :id="'otpInput' + index"
                  :key="index"
                  :ref="'otpInput' + index"
                  v-model="otpInputs[index]"
                  :maxlength="1"
                  type="text"
                  inputmode="numeric"
                  @input="handleInput(index)"
                  @keydown="handleBackspace(index)"
                  @paste.prevent="handlePaste"
                >
              </div>
            </form>
            <p
              v-if="activateError"
              class="text-center mt-2"
            >
              <span>OTP Code is expired?</span>
              <b-link @click="resendActivation">
                <span>&nbsp;Resend</span>
              </b-link>
            </p>
            <b-form-input
              id="topyenoh"
              v-model="topyenoh"
              class="topyenoh"
              name="topyenoh"
            />
          </b-col>
        </b-overlay>
      </b-col>
      <!-- /OTP-->

    </b-row>

  </div>

</template>

<script>
/* eslint-disable global-require */
import TallySaleLogo from '@/layouts/components/Logo.vue'
import {
  BOverlay, BRow, BCol, BLink, BCardTitle, BCardText, BImg, BFormInput,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  components: {
    BOverlay,
    TallySaleLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BFormInput,
    BCardTitle,
    BCardText,
  },
  data() {
    return {
      isLoading: false,
      userEmail: '',
      topyenoh: '',
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      required,
      email,
      otpInputs: ['', '', '', '', '', ''],
      activateError: false,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  watch: {
    otpInputs: {
      handler(newVal) {
        if (newVal.every(input => input !== '')) {
          this.submitOTP()
        }
      },
      deep: true,
    },
  },
  mounted() {
    // this.activateUser()
    // Focus on the first input element when the component is mounted
    this.$refs.otpInput0[0].focus()
  },
  methods: {
    submitOTP() {
      // Combine OTP inputs into a single string
      const otp = this.otpInputs.join('')

      // submit OTP here
      this.activateUser(otp)
    },
    handleInput(index) {
      if (index < this.otpInputs.length - 1 && this.otpInputs[index].trim() !== '') {
        this.$refs[`otpInput${index + 1}`][0].focus()
      }
    },
    handleBackspace(index) {
      // eslint-disable-next-line no-restricted-globals
      if (event.key === 'Backspace' && index > 0 && this.otpInputs[index].trim() === '') {
        this.$refs[`otpInput${index - 1}`][0].focus()
      }
    },
    handlePaste(event) {
      const clipboardData = event.clipboardData || window.clipboardData
      const pastedData = clipboardData.getData('text').trim()

      if (pastedData.length === this.otpInputs.length) {
        pastedData.split('').forEach((char, index) => {
          this.$set(this.otpInputs, index, char)
        })
      }
      this.$refs.otpInput5[0].focus()
      event.preventDefault()
    },
    async activateUser(token) {
      this.isLoading = true
      // const { token } = this.$router.currentRoute.query
      const userEmail = await localStorage.getItem('activateUser')
      if (token) {
        useJwt.activateUser({
          activate_user_token: token,
          email: userEmail,
        })
        .then(response => {
          this.authExecute(response)
          localStorage.removeItem('activateUser')
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              text: 'Invalid OTP code for activation, Please try again.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: 5000,
            },
          })
          this.activateError = true
          this.otpInputs = ['', '', '', '', '', '']
          this.$refs.otpInput0[0].focus()
        })
      }
    },
    authExecute(response) {
      const { userData, employeeData } = response.data
      useJwt.setToken(response.data.accessToken)
      localStorage.setItem('userData', JSON.stringify(userData))
      localStorage.setItem('employeeData', JSON.stringify(employeeData))

      const { branch } = employeeData
      const configData = [
        { name: 'business_name', value: branch.name },
        { name: 'business_description', value: branch.description },
        { name: 'business_phone', value: branch.phone },
        { name: 'business_website', value: '' },
        { name: 'business_email', value: branch.email },
        { name: 'business_address', value: branch.address },
        { name: 'business_logo', value: branch.logo },
      ]
      localStorage.setItem('configData', JSON.stringify(configData))
      this.$ability.update(userData.role.abilities)

      // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
      this.$router.replace(getHomeRouteForLoggedInUser(userData.role.name))
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Welcome ${employeeData.name || userData.name}`,
              autoHideDelay: '3000',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'You have successfully logged. Now you can start to explore, learn and use Tally Sale!',
            },
          })
        })
    },
    async resendActivation() {
      this.isLoading = true
      const userEmail = await localStorage.getItem('activateUser')
      useJwt.resendActivationOTP({
        email: userEmail,
      })
      .then(response => {
        if (response) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                text: 'OTP Code is sent to your email, check and enter OTP Code again.',
                icon: 'AlertTriangleIcon',
                variant: 'success',
                timeout: 5000,
              },
            })
          }
        this.isLoading = false
        this.$refs.otpInput0[0].focus()
      })
      .catch(error => {
        this.isLoading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: error.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: 5000,
            },
          })
          this.activateError = true
          this.otpInputs = ['', '', '', '', '', '']
          this.$refs.otpInput0[0].focus()
      })
      console.log('OTP resent')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<style scoped>
.container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.otp-input {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
}

.otp-input input {
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 18px;
}

.otp-form button {
  margin-top: 10px;
}
</style>
